import styled from "styled-components";

export const Principal = styled.div`
    background-color: var(--primary);
    padding: 100px 0 0 0;

    .grid{
        display: grid;
        grid-template-columns: 44% auto;
        .contato{
            width: 270px;
            margin: 0 auto;
            padding-top: 100px;
            .fones{
                margin-left: 20px;
            }
            h1{
                font-family: 'Ubuntu', sans-serif;
                font-weight: bold;
                color: var(--secondary-text);
                font-size: 40px;
                line-height: 46px;
                margin-top: 50px;
            }
            p{
                font-family: 'Ubuntu', sans-serif;
                font-weight: bold;
                color: var(--secondary-text);
                font-size: 1.25rem;
                line-height: 1.375rem;
                margin-top: 1.25rem;
                margin-inline: auto;
            }
            a{
                font-size: 1.25rem;
                line-height: 1.875rem;
                font-family: 'Ubuntu', sans-serif;
                display: block;
                text-decoration: none;
                color: var(--secondary-text);
                margin-top: 0.563rem;
            }
            @media (max-width: 48rem) {
                h1 {
                    text-align: center;
                }
                .fones {
                    margin-left: 0;
                }
                p, a {
                    text-align: left;
                }
                img {
                    position: relative;
                    margin-left: -1rem;
                }
            }
        }
        .form{
            background-color: var(--primary);
            padding-top: 50px;
            form{
                max-width: 540px;
                margin: 0 auto;
                padding: 0 10px;
            }
            select, input, textarea{ 
                border-radius: 10px;
                width: 100%;
                background: #f0f0f0;
                padding: 15px;
                border: none;
                margin: 0 0 12px;
                color: #707070;
                font-size: 14px;
                outline: none;
            }
            select + .error-icon {
                margin-bottom: 0.5rem;
            }
            button{
                min-width: 12.5rem;
                min-height: 2.75rem;
                background-color: var(--secondary-text);
                font-family: 'ubuntu';
                font-weight: bold;
                font-size: 20px;
                color: var(secondary-text);
                margin: 15px auto;
                border: none;
                border-radius: 30px;
                float: right;
            }
            .error-msg {
                font-family: "Ubuntu", sans-serif;
                display: inline-flex;
                padding-left: 3rem;
                align-items: center;
                max-width: 33.625rem;
                width: 100%;
                min-height: 2.75rem;
                background-color: var(--error-msg-bg);
                color: var(--error-msg-txt);
                margin-top: 0.625rem;
                border-radius: 1.938rem;
                font-weight: 700;
                & + .error-icon {
                    text-align: center;
                    display: block;
                    font-weight: 700;
                    background-color: var(--error-icon-primary);
                    color: var(--error-icon-txt-primary);
                    min-height: 1.375rem;
                    min-width: 1.375rem;
                    border-radius: 50%;
                    margin-top: -2.113rem;
                    padding-top: 0.125rem;
                    margin-left: 0.625rem;
                    position: absolute;
                }
            }
        }
        @media (max-width: 48em) {
            grid-template-columns: 1fr;
            padding: 0 2rem;
            .form {
                margin-inline: auto;
                max-width: 25rem;
                background-color: var(--primary);
                button {
                    display: block;
                    width: 100%;
                    margin-top: 2rem;
                    margin-left: auto;
                    margin-right: auto;
                    float: unset;
                }
            }
            .contato {
                text-align: center;
                padding-bottom: 30px;            
            }
        }
    }
    & + div {
        margin-top: 0;
        padding-top: 5rem;
        @media (max-width: 80em) {
            margin-top: 0%;
            padding-top: 3rem;
        }
    }
    `;

    export const ContatoContainer = styled.div`
        .header-container {
            .desk .btn-desk {
                button {
                    background-color: var(--secondary-bg);
                    color: var(--btn-primary-txt);
                }
            }
        }    
    `